body {
	font-family: 'Poppins', sans-serif;
}

.param-field {
	padding: 20px;
	border-bottom: 2px solid #CFCFCF;
}

.form-head {
	border-bottom: 2px solid #CFCFCF;
}

.brand-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 0;
	margin: auto;
	width: 80%;
}

.brand-header .logo {
	background-color: white;
}

.brand-header .name {
	background-color: white;
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.banner {
	display: flex;
	height: 140px;
	background-image: url(/assets/page_banner.png);
	background-size: cover;
	background-position: center;
}

.banner .title {
	font-size: 16px;
	margin: auto;
	margin-left: 15%;
	color: white;
}

input.form-control, input.form-control:focus, select.form-control, select.form-control:focus {
	background: #ECF0F5;

}

input.form-control:disabled {
	background: #d4d4d4 !important;
}

.radio-field {
	display: inline-flex;
	align-items: center;
	font-size: 18px;
	margin: 0;
	cursor: pointer;
}

.radio-field input[type='radio'] {
	-webkit-appearance: none;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin-right: 18px;
	outline: none;
	box-shadow: 0 0 0 2px gray;
}

.radio-field input[type='radio']:checked {
	box-shadow: 0 0 0 2px var(--primary-color);
}

.radio-field input[type='radio']:before {
	content: '';
	display: block;
	width: 45%;
	height: 50%;
	margin: 25% auto;
	border-radius: 50%;
}

.radio-field input[type='radio']:checked:before {
	background: var(--primary-color);
}

.primary-btn {
	background: var(--primary-color);
	font-size: 14px;
	font-weight: 500;
	height: 44px;
	border: none;
	border-radius: 6px;
	width: 160px;
	color: white;
}

.primary-btn.outline {
	background: white;
	border: 1px solid #DBDBDB;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.04);
	border-radius: 6px;
	width: 92px;
	color: var(--primary-color);
}

.center-btn-icon {
	display: flex !important;
	justify-content: center;
    align-items: center;
}

.center-mat-icon {
	display: inline-flex !important;
	align-items: center;
}

@media only screen and (max-width: 768px) {
	.radio-field input[type='radio'] {
		width: 15px;
		height: 15px;
	}
}

/* Color Variables */

:root {
	--primary-color: #A13178;
}